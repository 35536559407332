@if (items()) {
    @for (item of items(); track item.id; let i = $index) {
        <div class="{{ item.backgroundColor ? item.backgroundColor + ' md:bg-primary' : 'bg-primary' }}">
            <div
                class="flex container gap-2 text-center text-white tracking-[0.5px] overflow-y-hidden content-start justify-center"
                [@leaveAnimation]>
                <div
                    class="p-2 break-words font-ci [&>*:last-child]:mb-0"
                    [innerHTML]="item.html"></div>
                <big-ui-button
                    [id]="item.id"
                    (clicked)="closeTopBar(i)"
                    class="leading-[0] py-2.5">
                    <span class="sr-only">{{ 'topBar.closeMessage' | transloco }}</span>
                    <span>
                        <big-icon
                            [icon]="iconClose"
                            [size]="'w-5'"
                            class="fill-white" />
                    </span>
                </big-ui-button>
            </div>
        </div>
    }
}
