import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, computed, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TabNavService, type TabNavLink } from '@big-direkt/a11y';
import { MobileWizardComponent, PageNavigationService } from '@big-direkt/form/view';
import { SearchFormComponent } from '@big-direkt/search';
import { UserRepository } from '@big-direkt/state/user';
import { UiHeaderComponent } from '@big-direkt/ui/header';
import { UiLinkModule } from '@big-direkt/ui/link';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumBenutzer, IconBigMediumBurgerMenu, IconBigMediumSuche, IconBigMediumTelefon24H, IconComponent } from '@big-direkt/utils/icons';
import { BreakpointService } from '@big-direkt/utils/shared';
import { TranslocoPipe } from '@jsverse/transloco';
import { lastValueFrom, tap } from 'rxjs';
import { NavService } from '../../services/nav.service';
import { SidenavService } from '../../services/sidenav.service';
import { NavMainDesktopComponent } from '../nav-main-desktop/nav-main-desktop.component';
import { NavQuicknavComponent } from '../nav-quicknav/nav-quicknav.component';
import { NavUserButtonComponent } from '../nav-user/nav-user-button.component';
import { HeaderContext } from './../../models/header-context.model';
import { NavMobileIconButtonComponent } from './nav-mobile-icon-button.component';

@Component({
    selector: 'big-nav-header',
    standalone: true,
    imports: [
        IconComponent,
        NavQuicknavComponent,
        NavMainDesktopComponent,
        NavUserButtonComponent,
        NgClass,
        UiLinkModule,
        UiHeaderComponent,
        MobileWizardComponent,
        SearchFormComponent,
        NavMobileIconButtonComponent,
        TranslocoPipe,
    ],
    providers: [provideTranslationScope('nav', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`))],
    templateUrl: './nav-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavHeaderComponent {
    private readonly a11yTabNavService = inject(TabNavService);
    private readonly breakpointService = inject(BreakpointService);
    private readonly environmentService = inject(EnvironmentService);
    private readonly navService = inject(NavService);
    private readonly pageNavigationService = inject(PageNavigationService);
    private readonly sidenavService = inject(SidenavService);
    private readonly userRepository = inject(UserRepository);

    public readonly desktopHeaderToolsLink: TabNavLink = {
        title: 'a11yTabNav.headerToolsLink.title',
        key: 'header-tools-desktop',
        displayValue: 'a11yTabNav.headerToolsLink.displayValue',
        viewPort: 'desktop',
        order: 5,
    };
    public readonly mobileContactLink: TabNavLink = {
        title: 'a11yTabNav.contactLink.title',
        key: 'contact-mobile',
        displayValue: 'a11yTabNav.contactLink.displayValue',
        viewPort: 'mobile',
        order: 5,
    };
    public readonly desktopContactLink: TabNavLink = {
        ...this.mobileContactLink,
        key: 'contact-desktop',
        viewPort: 'desktop',
    };
    public readonly mobileMenuLink: TabNavLink = {
        title: 'a11yTabNav.menuLink.title',
        key: 'menu-mobile',
        displayValue: 'a11yTabNav.menuLink.displayValue',
        viewPort: 'mobile',
        order: 10,
    };
    public readonly desktopMenuLink: TabNavLink = {
        ...this.mobileMenuLink,
        key: 'menu-desktop',
        viewPort: 'desktop',
    };

    public readonly iconUser = IconBigMediumBenutzer;
    public readonly iconMenu = IconBigMediumBurgerMenu;
    public readonly iconPhone = IconBigMediumTelefon24H;
    public readonly iconSearch = IconBigMediumSuche;
    public readonly isUserLoggedIn = signal(false);
    public readonly servicePhoneNumber = this.environmentService.servicePhoneNumber;
    public readonly privatePath = this.environmentService.privatePath;
    public readonly centeredTitle = input<string>();
    public readonly showSearch = input<boolean>(true);
    public readonly headerContext = input.required<HeaderContext>();

    @HostBinding('class') public classList = 'block';

    public menus = this.navService.headerMenus;

    public isSidenavOpen = computed(() => !!this.sidenavService.currentSidenav());
    public isMobileUserNavOpen = computed(() => this.sidenavService.currentSidenav() === 'user');
    public isWizardVisible = computed(() => !!this.pageNavigationService.getCurrentPageSignal()()?.isWizardVisible);

    public constructor() {
        this.a11yTabNavService.registerLinks(
            this.desktopHeaderToolsLink,
            this.mobileContactLink,
            this.desktopContactLink,
            this.mobileMenuLink,
            this.desktopMenuLink,
        );

        // eslint-disable-next-line sonarjs/no-async-constructor
        void lastValueFrom(
            this.userRepository.isLoggedIn$().pipe(
                takeUntilDestroyed(),
                tap(isLoggedIn => {
                    this.isUserLoggedIn.set(isLoggedIn);
                }),
            ),
        );
    }

    public closeMobileNav(): void {
        this.sidenavService.closeSidenav();
    }

    @HostListener('window:scroll')
    private onScroll(): void {
        this.breakpointService.scrollPositionY();
    }
}
