import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ServiceToolBaseComponent, ServiceToolFieldTrackingDirective } from '@big-direkt/service-tools/base';
import { UiAccordionItemComponent } from '@big-direkt/ui/accordion';
import { UiAlertComponent } from '@big-direkt/ui/alert';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { ButtonGroupDataModel, UiButtonGroupComponent } from '@big-direkt/ui/button-group';
import { UiBarChartBar, UiBarChartComponent, UiBarChartConfig } from '@big-direkt/ui/chart';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { UiInputComponent, UiNumberInputComponent } from '@big-direkt/ui/input';
import { SelectOption, UiSelectComponent } from '@big-direkt/ui/select';
import { ScrollService, WindowService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumDokument, IconBigMediumPfeilLinks, IconComponent } from '@big-direkt/utils/icons';
import { TranslocoDirective } from '@jsverse/transloco';
import { DiabetesRiskDiagnosisForm } from '../models/diabetes-risk-diagnosis-form.model';
import { DiabetesRiskDiagnosisResults, DiabetesRiskDiagnosisResultsBlockItem } from '../models/diabetes-risk-diagnosis-results.model';
import { DiabetesDiagnosisService } from '../services/diabetes-risk-diagnosis.service';
import { calculateBmi } from '../utils/calculate-bmi';
import BmiTableComponent from './results/bmi-table.component';
import OfferedServicesComponent from './results/offered-services.component';

const MEASURE_DEFS = [
    { percentage: 0, label: '' },
    { percentage: 2, label: '1%' },
    { percentage: 10, label: '5%' },
    { percentage: 20, label: '10%' },
    { percentage: 30, label: '15%' },
    { percentage: 40, label: '20%' },
    { percentage: 50, label: '25%' },
    { percentage: 66.6, label: '50%' },
    { percentage: 83.3, label: '75%' },
    { percentage: 100, label: '100%' },
];
@Component({
    selector: 'big-service-tools-diabetes-diagnosis-risk-diagnoses',
    standalone: true,
    templateUrl: './risk-diagnosis.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideTranslationScope('stDiabetesDiagnosis', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`)),
    ],
    imports: [
        BmiTableComponent,
        FormsModule,
        IconComponent,
        OfferedServicesComponent,
        ReactiveFormsModule,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiAccordionItemComponent,
        UiAlertComponent,
        UiBarChartComponent,
        UiButtonComponent,
        UiButtonGroupComponent,
        UiFormRowComponent,
        UiInputComponent,
        UiNumberInputComponent,
        UiSelectComponent,
    ],
})
export class RiskDiagnosisComponent extends ServiceToolBaseComponent<DiabetesRiskDiagnosisForm> {
    private readonly scrollService = inject(ScrollService);
    private readonly diabetesDiagnosisService = inject(DiabetesDiagnosisService);
    private readonly windowService = inject(WindowService);
    private readonly destroyRef = inject(DestroyRef);

    public readonly documentIcon = IconBigMediumDokument;
    public readonly arrowIcon = IconBigMediumPfeilLinks;

    public readonly riskBlock: WritableSignal<DiabetesRiskDiagnosisResultsBlockItem | undefined> = signal(undefined);
    public readonly bmi: WritableSignal<number | undefined> = signal(undefined);
    public readonly riskPointsRange: WritableSignal<keyof DiabetesRiskDiagnosisResults | undefined> = signal(undefined);
    public readonly riskBar: WritableSignal<UiBarChartBar | undefined> = signal(undefined);
    public readonly barChartValues: WritableSignal<UiBarChartConfig> = signal({
        bars: [],
        measures: MEASURE_DEFS,
    });

    public yesNoOptions: ButtonGroupDataModel<boolean>[] = [
        {
            label: 'stDiabetesDiagnosis.label.yes',
            value: true,
        },
        {
            label: 'stDiabetesDiagnosis.label.no',
            value: false,
        },
    ];

    public genderOptions: ButtonGroupDataModel<DiabetesRiskDiagnosisForm['gender']['value']>[] = [
        {
            label: 'stDiabetesDiagnosis.label.genderOptions.male',
            value: 'm',
        },
        {
            label: 'stDiabetesDiagnosis.label.genderOptions.female',
            value: 'f',
        },
    ];

    public bodyWaistSizeOptionsFemale: ButtonGroupDataModel<DiabetesRiskDiagnosisForm['bodyWaistSize']['value']>[] = [
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.lt80',
            value: 'lt80',
        },
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.80to88',
            value: '80to88',
        },
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.gt88',
            value: 'gt88',
        },
    ];

    public bodyWaistSizeOptionsMale: ButtonGroupDataModel<DiabetesRiskDiagnosisForm['bodyWaistSize']['value']>[] = [
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.lt94',
            value: 'lt94',
        },
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.94to102',
            value: '94to102',
        },
        {
            label: 'stDiabetesDiagnosis.label.bodyWaistSizeOptions.gt102',
            value: 'gt102',
        },
    ];

    public eatsHighFiberFoodsOptions: ButtonGroupDataModel<DiabetesRiskDiagnosisForm['eatsHighFiberFoods']['value']>[] = [
        {
            label: 'stDiabetesDiagnosis.label.eatsHighFiberFoodsOptions.yes',
            value: true,
        },
        {
            label: 'stDiabetesDiagnosis.label.eatsHighFiberFoodsOptions.no',
            value: false,
        },
    ];

    public ageOptions: SelectOption<DiabetesRiskDiagnosisForm['age']['value']>[] = [
        {
            key: 'label.ageOptions.lt35',
            value: 'lt35',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.ageOptions.35to44',
            value: '35to44',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.ageOptions.45to54',
            value: '45to54',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.ageOptions.55to64',
            value: '55to64',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.ageOptions.gt65',
            value: 'gt65',
            scope: 'stDiabetesDiagnosis',
        },
    ];

    public hasRelativesWithDiabetesOptions: SelectOption<DiabetesRiskDiagnosisForm['hasRelativesWithDiabetes']['value']>[] = [
        {
            key: 'label.hasRelativesWithDiabetesOptions.unkown',
            value: 'no',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.hasRelativesWithDiabetesOptions.closeRelationship',
            value: 'closeRelationship',
            scope: 'stDiabetesDiagnosis',
        },
        {
            key: 'label.hasRelativesWithDiabetesOptions.distantRelationship',
            value: 'distantRelationship',
            scope: 'stDiabetesDiagnosis',
        },
    ];

    public constructor() {
        super();

        /* eslint-disable @typescript-eslint/unbound-method */
        this.form = this.formBuilder.group({
            gender: new FormControl<DiabetesRiskDiagnosisForm['gender']['value']>(undefined, [Validators.required]),
            age: new FormControl<DiabetesRiskDiagnosisForm['age']['value']>('', [Validators.required]),
            bodySize: new FormControl<DiabetesRiskDiagnosisForm['bodySize']['value']>(undefined, [
                Validators.required,
                Validators.min(0),
                Validators.max(250), // eslint-disable-line @typescript-eslint/no-magic-numbers
            ]),
            bodyWeight: new FormControl<DiabetesRiskDiagnosisForm['bodyWeight']['value']>(undefined, [
                Validators.required,
                Validators.min(0),
                Validators.max(200), // eslint-disable-line @typescript-eslint/no-magic-numbers
            ]),
            bodyWaistSize: new FormControl<DiabetesRiskDiagnosisForm['bodyWaistSize']['value']>(undefined, [Validators.required]),
            doesSports: new FormControl<DiabetesRiskDiagnosisForm['doesSports']['value']>(undefined, [Validators.required]),
            eatsHighFiberFoods: new FormControl<DiabetesRiskDiagnosisForm['eatsHighFiberFoods']['value']>(undefined, [Validators.required]),
            isSmoker: new FormControl<DiabetesRiskDiagnosisForm['isSmoker']['value']>(undefined, [Validators.required]),
            highBloodSugarLevels: new FormControl<DiabetesRiskDiagnosisForm['highBloodSugarLevels']['value']>(undefined, [Validators.required]),
            medicationBloodPressure: new FormControl<DiabetesRiskDiagnosisForm['medicationBloodPressure']['value']>(undefined, [Validators.required]),
            hasRelativesWithDiabetes: new FormControl<DiabetesRiskDiagnosisForm['hasRelativesWithDiabetes']['value']>('', [Validators.required]),
        });
        /* eslint-enable @typescript-eslint/unbound-method */

        this.form.controls.gender.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.form?.controls.bodyWaistSize.reset());
    }

    public submit(event: Event): void {
        event.preventDefault();

        this.hasBeenSubmitted = true;
        this.trackStFormSubmitEvent();

        if (!this.form?.valid) {
            this.scrollService.scroll('big-service-tools-diabetes-diagnosis-risk-diagnoses .ng-invalid');

            return;
        }

        const formControls = this.form.controls;

        const bmi = this.calculateBmi();

        if (!bmi) {
            return;
        }

        this.bmi.set(bmi);

        const riskPoints = this.diabetesDiagnosisService.getRiskPoints(
            formControls.age.value,
            formControls.hasRelativesWithDiabetes.value,
            formControls.bodyWaistSize.value,
            formControls.doesSports.value,
            formControls.eatsHighFiberFoods.value,
            formControls.medicationBloodPressure.value,
            formControls.highBloodSugarLevels.value,
            bmi,
        );
        this.riskPointsRange.set(this.diabetesDiagnosisService.getPointsRange(riskPoints));

        const riskBarSelf = this.diabetesDiagnosisService.getBarValueByPoints(riskPoints);
        this.riskBar.set(riskBarSelf);

        const genderReferenceBar = this.diabetesDiagnosisService.getBarByGenderReference(formControls.gender.value, formControls.age.value);

        this.barChartValues.set({
            measures: this.barChartValues().measures,
            bars: [riskBarSelf, genderReferenceBar],
        });

        const riskBlock = this.diabetesDiagnosisService.getDiagnosisBlock(riskPoints, bmi);
        this.riskBlock.set(riskBlock);

        this.scrollService.scroll('big-service-tools-diabetes-diagnosis-risk-diagnoses');
    }

    public restart(event: Event): void {
        event.preventDefault();

        this.riskBlock.set(undefined);
        this.form?.reset();
        this.form?.controls.age.setValue('');
        this.form?.controls.hasRelativesWithDiabetes.setValue('');
        this.hasBeenSubmitted = false;
        this.scrollService.scroll('big-service-tools-diabetes-diagnosis-risk-diagnoses');
    }

    public print(event: Event): void {
        event.preventDefault();

        this.windowService.nativeWindow()?.print();
    }

    private calculateBmi(): number | undefined {
        const formControls = this.form?.controls;

        if (!formControls?.bodyWeight.value || !formControls.bodySize.value) {
            return undefined;
        }

        return calculateBmi(formControls.bodyWeight.value, formControls.bodySize.value);
    }
}

export default RiskDiagnosisComponent;
