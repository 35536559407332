import { AsyncPipe, formatCurrency } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, LOCALE_ID, inject, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators, type FormGroup } from '@angular/forms';
import { ResourceTypes, RestApiClientService } from '@big-direkt/rest-api-client';
import { ServiceToolBaseComponent, ServiceToolFieldTrackingDirective, type TypedOrUntypedAbstractControl } from '@big-direkt/service-tools/base';
import { UiAlertComponent } from '@big-direkt/ui/alert';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiErrorComponent } from '@big-direkt/ui/error';
import { UiCurrencyInputComponent } from '@big-direkt/ui/input';
import { UiLabelComponent } from '@big-direkt/ui/label';
import { UiSelectComponent, type SelectOption } from '@big-direkt/ui/select';
import { UiTableComponent } from '@big-direkt/ui/table';
import { EnvironmentService, ErrorHandlingService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import {
    truncateAfterTwoDecimals,
    type TableCompositeCellType,
    type TableHeaderCellModel,
    type TableRowModel,
    type TableStringCellModel,
} from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { catchError, tap } from 'rxjs';
import { type BonusInfo } from '../models/bonus-info.model';
import { type EvidenceTeethForm } from '../models/evidence-teeth-form.model';
import { type RegularTreatmentOption } from '../models/regular-treatment-option.type';
import { type ToothReplacementSubsidyForm } from '../models/tooth-replacement-subsidy-form.model';
import { type ToothReplacementSubsidyTableRowData } from '../models/tooth-replacement-subsidy-table-data.model';
import { type ToothReplacementSubsidyModel } from '../models/tooth-replacement-subsidy.model';
import { type TreatmentInfoForm } from '../models/treatment-info-form.model';

@Component({
    selector: 'big-service-tool-tooth-replacement-subsidy',
    standalone: true,
    imports: [
        AsyncPipe,
        FormsModule,
        ReactiveFormsModule,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiAlertComponent,
        UiButtonComponent,
        UiCurrencyInputComponent,
        UiErrorComponent,
        UiLabelComponent,
        UiSelectComponent,
        UiTableComponent,
    ],
    templateUrl: './tooth-replacement-subsidy.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideTranslationScope(
            'stToothReplacementSubsidy',
            /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`),
        ),
    ],
})
export class ToothReplacementSubsidyComponent extends ServiceToolBaseComponent<ToothReplacementSubsidyForm> implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly environmentService = inject(EnvironmentService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly locale = inject(LOCALE_ID);
    private readonly numberOfTeethOptionEntries = 10;
    private readonly restApiClient = inject(RestApiClientService);
    private readonly rowSubventionCellIndex = 2;
    private readonly rowSumCellIndex = 3;
    private readonly tableData: ToothReplacementSubsidyTableRowData[] = [];

    public readonly errorPhoneNumber = this.environmentService.errorPhoneNumber;
    public readonly numberOfTeethOptions: SelectOption<number>[] = [];
    public readonly selectErrorOverrides = { required: 'stToothReplacementSubsidy.error.selectRequired' };

    public treatmentInfoForm: FormGroup<TreatmentInfoForm>;
    public evidenceAndTeethForm: FormGroup<EvidenceTeethForm>;

    public regularTreatmentOptions: SelectOption<RegularTreatmentOption>[] = [];
    public evidenceNummberOptions: SelectOption<ToothReplacementSubsidyModel>[] = [];

    public tableHeaders: TableHeaderCellModel[] = [
        { value: 'stToothReplacementSubsidy.table.description' },
        { value: 'stToothReplacementSubsidy.table.count' },
        { value: 'stToothReplacementSubsidy.table.subsidyPerTooth' },
        { value: 'stToothReplacementSubsidy.table.sum' },
        { value: ' ' },
    ];

    public tableRows: TableRowModel = [];
    public tableFooterRows: TableRowModel = [
        [
            {
                type: 'string',
                value: 'stToothReplacementSubsidy.table.totalSubsidy',
                colSpan: 3,
                cssClass: 'border-t-2 border-black border-double !border-r-0',
            },
            { type: 'string', value: undefined, colSpan: 2, cssClass: 'border-t-2 border-black border-double !border-r-0' },
        ],
        [
            { type: 'string', value: 'stToothReplacementSubsidy.table.ownContribution', colSpan: 3, cssClass: '!font-normal !border-r-0' },
            { type: 'string', value: undefined, colSpan: 2 },
        ],
        [
            { type: 'string', value: 'stToothReplacementSubsidy.table.bonusSubsidy', colSpan: 3, cssClass: '!font-normal !border-r-0' },
            { type: 'string', value: undefined, colSpan: 2 },
        ],
    ];

    public hasDataLoadingError = false;
    public hasBeenReset = false;
    private totalSubsidy = 0;
    private ownContribution = 0;
    private bonusInfo!: BonusInfo;

    private get totalSubsidyValueCell(): TableStringCellModel {
        return this.tableFooterRows[0][1] as TableStringCellModel;
    }

    private get ownContributionValueCell(): TableStringCellModel {
        return this.tableFooterRows[1][1] as TableStringCellModel;
    }

    private get bonusSubsidyValueCell(): TableStringCellModel {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return this.tableFooterRows[2][1] as TableStringCellModel;
    }

    public constructor() {
        super();
        /* eslint-disable @typescript-eslint/unbound-method */
        this.treatmentInfoForm = this.formBuilder.group({
            treatmentCost: new FormControl<number | undefined>(undefined, [Validators.required]),
            regularTreatment: new FormControl<RegularTreatmentOption | ''>('', [Validators.required]),
        });

        this.evidenceAndTeethForm = this.formBuilder.group({
            evidenceNumber: new FormControl<ToothReplacementSubsidyModel | ''>('', [Validators.required]),
            numberOfTeeth: new FormControl<number | ''>('', [Validators.required]),
        });
        /* eslint-enable@typescript-eslint/unbound-method */

        this.calculateBonusInfo();
    }

    public ngOnInit(): void {
        this.initializeSelectOptions();

        this.treatmentInfoForm.controls.regularTreatment.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((regularTreatment: RegularTreatmentOption | '' | null) => {
                if (!regularTreatment) {
                    return;
                }

                this.calculateBonusInfo(regularTreatment);
                this.recalculateTableValues(regularTreatment);
            });

        this.treatmentInfoForm.controls.treatmentCost.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((treatmentCost: number | null | undefined) => {
                this.calculateOwnContribution(treatmentCost);
            });
    }

    public submit(event: Event): void {
        event.preventDefault();
        this.hasBeenSubmitted = true;
        this.trackStFormSubmitEvent();

        if (
            !this.treatmentInfoForm.valid ||
            !this.evidenceAndTeethForm.valid ||
            !this.evidenceAndTeethForm.controls.evidenceNumber.value ||
            !this.evidenceAndTeethForm.controls.numberOfTeeth.value
        ) {
            this.hasBeenReset = false;

            return;
        }

        const tableRowData: ToothReplacementSubsidyTableRowData = {
            description: this.evidenceAndTeethForm.controls.evidenceNumber.value.title,
            numberOfTeeth: this.evidenceAndTeethForm.controls.numberOfTeeth.value,
            initialSubvention: this.evidenceAndTeethForm.controls.evidenceNumber.value.subvention,
            subvention: truncateAfterTwoDecimals(this.evidenceAndTeethForm.controls.evidenceNumber.value.subvention * this.bonusInfo.bonusSubsidyFactor),
            sum: truncateAfterTwoDecimals(
                this.evidenceAndTeethForm.controls.evidenceNumber.value.subvention *
                    this.evidenceAndTeethForm.controls.numberOfTeeth.value *
                    this.bonusInfo.bonusSubsidyFactor,
            ),
        };

        this.tableData.push(tableRowData);

        this.tableRows = [
            ...this.tableRows,
            [
                { type: 'string', value: tableRowData.description },
                { type: 'string', value: tableRowData.numberOfTeeth.toString() },
                { type: 'string', value: formatCurrency(tableRowData.subvention, this.locale, '€') },
                { type: 'string', value: formatCurrency(tableRowData.sum, this.locale, '€') },
                {
                    type: 'icon-button',
                    iconName: 'big-light-muelleimer',
                    labelKey: 'stToothReplacementSubsidy.table.deleteEntry',
                    buttonClassList: ['relative', 'w-full', 'block', 'text-center'],
                    iconClassList: 'fill-primary mx-auto',
                    iconSize: 'w-5',
                    onIconButtonClickHandler: (i: number): void => {
                        this.tableRows.splice(i, 1);
                        this.tableData.splice(i, 1);
                        this.calculateTotalSubsidy();
                    },
                },
            ],
        ];

        this.sortTable();
        this.calculateTotalSubsidy();
        this.calculateOwnContribution(this.treatmentInfoForm.controls.treatmentCost.value);
        this.calculateBonusSubsidy(this.treatmentInfoForm.controls.regularTreatment.value);

        this.resetControls();
    }

    protected override getFormControls(): TypedOrUntypedAbstractControl<ToothReplacementSubsidyForm> {
        return { ...this.treatmentInfoForm.controls, ...this.evidenceAndTeethForm.controls };
    }

    private sortTable(): void {
        this.tableRows.sort((a: TableCompositeCellType[], b: TableCompositeCellType[]) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (a[0] as TableStringCellModel).value! < (b[0] as TableStringCellModel).value! ? -1 : 1,
        );

        this.tableData.sort((a: ToothReplacementSubsidyTableRowData, b: ToothReplacementSubsidyTableRowData) => (a.description < b.description ? -1 : 1));
    }

    private recalculateTableValues(regularTreatment: RegularTreatmentOption | null | undefined): void {
        if (this.tableData.length <= 0 || !regularTreatment) {
            return;
        }

        for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].subvention = this.tableData[i].initialSubvention * this.bonusInfo.bonusSubsidyFactor;
            this.tableData[i].sum = this.tableData[i].subvention * this.tableData[i].numberOfTeeth;

            (this.tableRows[i][this.rowSubventionCellIndex] as TableStringCellModel).value = formatCurrency(this.tableData[i].subvention, this.locale, '€');
            (this.tableRows[i][this.rowSumCellIndex] as TableStringCellModel).value = formatCurrency(this.tableData[i].sum, this.locale, '€');
        }

        this.calculateTotalSubsidy();
        this.calculateOwnContribution(this.treatmentInfoForm.controls.treatmentCost.value);
        this.calculateBonusSubsidy(this.treatmentInfoForm.controls.regularTreatment.value);
    }

    private calculateTotalSubsidy(): void {
        this.totalSubsidy = 0;
        this.tableData.forEach(data => {
            this.totalSubsidy += data.sum;
        });

        this.totalSubsidy = truncateAfterTwoDecimals(this.totalSubsidy);

        this.totalSubsidyValueCell.value = formatCurrency(truncateAfterTwoDecimals(this.totalSubsidy), this.locale, '€');

        this.tableFooterRows = [...this.tableFooterRows];
    }

    private calculateOwnContribution(treatmentCost: number | null | undefined): void {
        if (this.tableData.length <= 0 || !treatmentCost) {
            return;
        }

        this.ownContribution = truncateAfterTwoDecimals((treatmentCost < this.totalSubsidy ? this.totalSubsidy : treatmentCost) - this.totalSubsidy);

        this.ownContributionValueCell.value = formatCurrency(this.ownContribution, this.locale, '€');

        this.tableFooterRows = [...this.tableFooterRows];
    }

    private calculateBonusSubsidy(regularTreatment: RegularTreatmentOption | '' | null): void {
        if (this.tableData.length <= 0 || !regularTreatment) {
            return;
        }

        this.calculateBonusInfo(regularTreatment);

        this.bonusSubsidyValueCell.value = this.bonusInfo.bonusMessage;

        this.tableFooterRows = [...this.tableFooterRows];
    }

    private initializeSelectOptions(): void {
        this.regularTreatmentOptions = [
            { scope: 'stToothReplacementSubsidy', key: 'label.lessThan5Years', value: 'lessThan5Years' },
            { scope: 'stToothReplacementSubsidy', key: 'label.moreThan5Years', value: 'moreThan5Years' },
            { scope: 'stToothReplacementSubsidy', key: 'label.moreThan10Years', value: 'moreThan10Years' },
        ];

        for (let i = 1; i < this.numberOfTeethOptionEntries + 1; i++) {
            this.numberOfTeethOptions.push({
                value: i,
                key: i.toString(),
            });
        }

        this.restApiClient
            .load<ToothReplacementSubsidyModel[]>(ResourceTypes.ToothReplacementSubsidyServiceToolData)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(data => {
                    data.forEach((entry: ToothReplacementSubsidyModel) => {
                        this.evidenceNummberOptions.push({
                            value: entry,
                            key: entry.title,
                        });
                    });
                }),
                catchError((error: Error) => {
                    this.hasDataLoadingError = true;

                    return this.errorHandlingService.catchError(error);
                }),
            )
            .subscribe();
    }

    private resetControls(): void {
        this.hasBeenReset = true;
        this.evidenceAndTeethForm.reset();
        this.evidenceAndTeethForm.controls.evidenceNumber.setValue('');
        this.evidenceAndTeethForm.controls.numberOfTeeth.setValue('');
        this.evidenceAndTeethForm.updateValueAndValidity();
    }

    private calculateBonusInfo(regularTreatment: RegularTreatmentOption = 'lessThan5Years'): void {
        this.bonusInfo = ((): BonusInfo => {
            switch (regularTreatment) {
                case 'moreThan5Years':
                    return {
                        bonusMessage: '70%',
                        bonusSubsidyFactor: 0.7,
                    };
                case 'moreThan10Years':
                    return {
                        bonusMessage: '75%',
                        bonusSubsidyFactor: 0.75,
                    };
                default:
                    return {
                        bonusMessage: '60%',
                        bonusSubsidyFactor: 0.6,
                    };
            }
        })();
    }
}

export default ToothReplacementSubsidyComponent;
