import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { TranslocoDirective } from '@jsverse/transloco';
import { DiabetesRiskDiagnosisResultsBlockItem } from '../../models/diabetes-risk-diagnosis-results.model';
import CardComponent from './card.component';

@Component({
    selector: 'big-service-tools-diabetes-diagnosis-offered-services',
    standalone: true,
    templateUrl: './offered-services.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideTranslationScope('stDiabetesDiagnosis', /* istanbul ignore next */ async (lang: string, root: string) => import(`../../${root}/${lang}.json`)),
    ],
    imports: [CardComponent, TranslocoDirective],
})
export default class OfferedServicesComponent {
    @HostBinding('class') public classList = 'block';

    public riskBlockItem = input.required<DiabetesRiskDiagnosisResultsBlockItem>();
    public isOlderThan35 = input.required<boolean>();
    public isSmoker = input.required<boolean>();
    public hasHighBloodPressure = input.required<boolean>();
    public signedUpForDmp = input<boolean>(true);

    public showBlock(block: string[], needles: string[]): boolean {
        return block.some(value => needles.includes(value));
    }
}
