@let checkupResult = result();

@if (form && !checkupResult) {
    <ng-container *transloco="let t; prefix: 'stDiabetesDiagnosis'">
        <big-ui-accordion-item
            [index]="0"
            [title]="t('privacy.title')"
            class="mb-6">
            <p>{{ t('privacy.block1preDiabetes') }}</p>
            <p>{{ t('privacy.block4') }}</p>
            <p>{{ t('privacy.block5') }}</p>
            <p>{{ t('privacy.block7') }}</p>
        </big-ui-accordion-item>

        <form
            [formGroup]="form"
            (ngSubmit)="submit($event)">
            <fieldset>
                <legend>
                    <h4>{{ t('title.age') }}</h4>
                </legend>
                <big-ui-form-row
                    #ageFormRow
                    [id]="'age'"
                    [label]="t('label.age')"
                    [isRequired]="true"
                    [errors]="form.controls.age.errors"
                    [showError]="hasBeenSubmitted">
                    <big-ui-number-input
                        [id]="'age'"
                        [isValid]="form.controls.age.valid"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        formControlName="age"
                        name="age"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: ageFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.age.valid,
                        }" />
                </big-ui-form-row>
            </fieldset>

            <fieldset class="mt-10">
                <legend>
                    <h4>{{ t('title.bodyMeasurements') }}</h4>
                    <p>{{ t('title.bodyMeasurementsDescription') }}</p>
                </legend>

                <big-ui-form-row
                    #heightFormRow
                    [label]="t('label.bodySize')"
                    [id]="'height'"
                    [isRequired]="true"
                    [errors]="form.controls.height.errors"
                    [showError]="hasBeenSubmitted">
                    <big-ui-number-input
                        class="w-full"
                        [isValid]="form.controls.height.valid"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'height'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: heightFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.height.valid,
                        }"
                        formControlName="height"
                        name="height" />
                </big-ui-form-row>

                <big-ui-form-row
                    #weightFormRow
                    [label]="t('label.bodyWeight')"
                    [id]="'weight'"
                    [isRequired]="true"
                    [errors]="form.controls.weight.errors"
                    [showError]="hasBeenSubmitted">
                    <big-ui-number-input
                        class="w-full"
                        [isValid]="form.controls.weight.valid"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'weight'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: weightFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.weight.valid,
                        }"
                        formControlName="weight"
                        name="weight" />
                </big-ui-form-row>
            </fieldset>

            <fieldset class="mt-10">
                <legend>
                    <h4>{{ t('title.lifestyle') }}</h4>
                </legend>

                <big-ui-form-row
                    #isSmokerFormRow
                    [label]="t('label.isSmoker')"
                    [id]="'isSmoker'"
                    [isRequired]="true"
                    [errors]="form.controls.isSmoker.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="isSmokerRadiogroup"
                    [infoTextTemplate]="isSmokerInfo"
                    (infoLayerOpened)="trackStInfoIconEvent(isSmokerFormRow.label!)">
                    <big-ui-button-group
                        class="w-full"
                        [isValid]="form.controls.isSmoker.valid"
                        [isTouched]="form.controls.isSmoker.touched"
                        radiogroupId="isSmokerRadiogroup"
                        [options]="yesNoOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'isSmoker'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: isSmokerFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.isSmoker.valid,
                        }"
                        formControlName="isSmoker"
                        name="isSmoker" />
                    <ng-template #isSmokerInfo>
                        {{ t('label.isSmokerHelpLayerBlock') }}
                    </ng-template>
                </big-ui-form-row>
            </fieldset>

            <fieldset class="mt-10">
                <legend>
                    <h4>{{ t('title.healthReports') }}</h4>
                </legend>

                <big-ui-form-row
                    #highCholosterolLevelFormRow
                    [label]="t('label.highCholosterolLevel')"
                    [id]="'highCholosterolLevel'"
                    [isRequired]="true"
                    [errors]="form.controls.highCholosterolLevel.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="highCholosterolLevelRadiogroup"
                    [infoTextTemplate]="highCholosterolLevelInfo"
                    (infoLayerOpened)="trackStInfoIconEvent(highCholosterolLevelFormRow.label!)">
                    <big-ui-button-group
                        class="w-full"
                        [isValid]="form.controls.highCholosterolLevel.valid"
                        [isTouched]="form.controls.highCholosterolLevel.touched"
                        radiogroupId="highCholosterolLevelRadiogroup"
                        [options]="yesNoOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'highCholosterolLevel'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: highCholosterolLevelFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.highCholosterolLevel.valid,
                        }"
                        formControlName="highCholosterolLevel"
                        name="highCholosterolLevel" />
                    <ng-template #highCholosterolLevelInfo>
                        {{ t('label.highCholosterolLevelHelpLayerBlock') }}
                    </ng-template>
                </big-ui-form-row>

                <big-ui-form-row
                    #highBloodPressureFormRow
                    [label]="t('label.highBloodPressure')"
                    [id]="'highBloodPressure'"
                    [isRequired]="true"
                    [errors]="form.controls.highBloodPressure.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="highBloodPressureRadiogroup"
                    [infoTextTemplate]="highBloodPressureInfo"
                    (infoLayerOpened)="trackStInfoIconEvent(highBloodPressureFormRow.label!)">
                    <big-ui-button-group
                        class="w-full"
                        [isValid]="form.controls.highBloodPressure.valid"
                        [isTouched]="form.controls.highBloodPressure.touched"
                        radiogroupId="highBloodPressureRadiogroup"
                        [options]="yesNoOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'highBloodPressure'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: highBloodPressureFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.highBloodPressure.valid,
                        }"
                        formControlName="highBloodPressure"
                        name="highBloodPressure" />
                    <ng-template #highBloodPressureInfo>
                        {{ t('label.highBloodPressureHelpLayerBlock') }}
                    </ng-template>
                </big-ui-form-row>
            </fieldset>

            <big-ui-button
                [buttonStyle]="'secondary'"
                [id]="'submit'"
                class="block mt-10 text-right"
                (clicked)="submit($event)">
                {{ t('label.prediabetesResult') }}
            </big-ui-button>
        </form>
    </ng-container>
}

@let bmiValue = bmi();
@if (checkupResult && bmiValue) {
    <ng-container *transloco="let t; prefix: 'stDiabetesDiagnosis'">
        <div class="space-y-16">
            <section>
                @let age = form?.controls?.age?.value;
                <big-service-tools-diabetes-diagnosis-offered-services
                    [riskBlockItem]="checkupResult"
                    [isOlderThan35]="!!(age && age >= 34)"
                    [hasHighBloodPressure]="!!form?.controls?.highBloodPressure?.value"
                    [isSmoker]="!!form?.controls?.isSmoker?.value" />
            </section>

            <section>
                <big-service-tools-diabetes-diagnosis-bmi-table [bodyMassIndex]="bmiValue" />
            </section>

            <section class="print:hidden">
                <h3 class="mb-6">{{ t('print.title') }}</h3>
                <p>{{ t('print.description') }}</p>

                <big-ui-button
                    [buttonStyle]="'secondary'"
                    [classList]="['flex', 'items-center']"
                    [id]="'print'"
                    (clicked)="print($event)"
                    class="block my-6">
                    {{ t('print.button') }}
                    <big-icon
                        [icon]="documentIcon"
                        [size]="'w-5'"
                        class="ml-2 fill-current" />
                </big-ui-button>
            </section>

            <button
                (click)="restart($event)"
                type="button"
                class="inline-flex space-x-2 font-bold text-primary hover:text-primary-light print:hidden">
                <big-icon
                    [icon]="arrowIcon"
                    [size]="'w-4'"
                    class="ml-2 translate-y-1 fill-current" />
                <span>{{ t('label.restartPrediabtes') }}</span>
            </button>
        </div>
    </ng-container>
}
