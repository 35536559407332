<div
    [ngClass]="{
        'relative max-w-[1920px] mx-auto': true,
        'min-h-[240px] md:min-h-[310px] lg:min-h-[370px] xl:min-h-[418px] mb-4': hasPicture && !isHome,
        'min-h-[488px] mb-[102px] md:min-h-[650px] md:mb-[182px] lg:min-h-[506px] overflow-visible': isHome,
        'min-h-[80px] md:min-h-[120px] flex flex-col items-center justify-center': hasTitle && showArc,
        'min-h-[64px] md:min-h-[82px] lg:min-h-[122px]': !hasPicture && !hasTitle && !isHome,
        'overflow-hidden': !isHome,
        'bg-gradient-to-b from-white to-gray-400 dark:from-[#152134] dark:to-[#243551]': (isEmpty || hasTitle) && showArc,
    }">
    @if (hasPicture) {
        <big-ui-picture
            [model]="model?.picture"
            class="absolute top-0 left-0 w-full h-full" />
    } @else {
        <div class="container relative z-pageTitleContent">
            @if (hasTitle) {
                <h1
                    [class.!m-0]="headingSize === 'h2'"
                    [ngClass]="['text-center', headingSize]">
                    {{ model?.title }}
                </h1>
            }
            <ng-content></ng-content>
        </div>
    }
    <!-- Horizontal gradient to both sides -->
    <ng-container *ngTemplateOutlet="horizontalGradient; context: { position: 'left' }" />
    <ng-container *ngTemplateOutlet="horizontalGradient; context: { position: 'right' }" />

    @if (showArc) {
        <div
            [ngClass]="{
                '-bottom-[0.5px]': true,
                'absolute': true,
                'h-[130px]': true,
                'left-0': true,
                'overflow-hidden': true,
                'right-0': true,
                'md:h-[180px]': true,
                'lg:h-[120px]': true,
                'z-pageTitle': true,
            }">
            <div
                [ngClass]="{
                    'absolute': true,
                    'bg-[radial-gradient(ellipse_at_top,_transparent_70%,_#fff_70.2%)] lg:bg-[radial-gradient(ellipse_at_top,_transparent_60%,_#fff_60.5%)]': true,
                    'bottom-0': true,
                    'dark:bg-[radial-gradient(ellipse_at_top,_transparent_70%,_rgb(22_33_52_/_var(--tw-bg-opacity))_70%)] dark:md:bg-[radial-gradient(ellipse_at_top,_transparent_60%,_rgb(22_33_52_/_var(--tw-bg-opacity))_60%)]': true,
                    'h-full': true,
                    'scale-x-[1.4] md:scale-x-[1.3]': hasTitle,
                    'scale-x-[2.6] lg:scale-x-[1.3]': !hasTitle,
                    'w-full': true,
                }"></div>
        </div>
    }

    @if (model?.homePageCard; as homePageCard) {
        <div
            class="container absolute top-0 bottom-0 flex flex-row items-end justify-center w-full -translate-x-1/2 md:justify-end left-1/2 z-pageTitleContent">
            <div
                class="relative -mb-6 shadow-card p-8 w-full bg-white dark:!bg-dark-contrast dark:shadow-none rounded-md sm:max-w-[478px] md:w-[450px] lg:w-[445px]">
                <p class="mb-2 overflow-hidden break-words h1">{{ homePageCard?.label }}</p>
                <p class="leading-8 text-[1.0625rem] tracking-[.2px] text-dark dark:!text-dark-primary overflow-hidden">{{ homePageCard?.content }}</p>
                @if (homePageCard?.linkUrl) {
                    <div>
                        <big-ui-link
                            [href]="homePageCard.linkUrl ?? ''"
                            [bigUiButtonStyle]="'primary'"
                            [classList]="['hover:bg-primary', 'hover:to-primary']"
                            [linkClass]="'hover:text-white no-underline after:absolute after:inset-0 z-pageTitle'">
                            {{ homePageCard?.linkTitle }}
                        </big-ui-link>
                    </div>
                }
            </div>
        </div>
    }
</div>

<ng-template
    #horizontalGradient
    let-position="position">
    <div
        class="absolute hidden h-full w-[10%] min-[1921px]:block dark:from-dark-background from-white left-0 ng-star-inserted"
        [ngClass]="{
            'left-0 bg-gradient-to-r': position === 'left',
            'right-0 bg-gradient-to-l': position === 'right',
        }"></div>
</ng-template>
