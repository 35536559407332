@if (menu) {
    <div
        *transloco="let t; prefix: 'nav'"
        aria-labelledby="myBigMenuDropDown">
        <div
            id="nav-user-content"
            class="relative flex flex-col">
            <div
                id="nav-user-header"
                class="flex flex-col gap-6 p-10 font-semibold text-white bg-primary">
                @if (profile()?.name) {
                    <div>
                        {{ t('greeting') }}
                        <br />
                        {{ profile()?.name.fullTitle }}
                        @if (profile()?.identifier?.distributionPartnerNumber) {
                            <br />
                            <span class="font-normal">{{ profile()?.identifier?.distributionPartnerNumber | displayDistributionPartnerId }}</span>
                        }
                    </div>
                }
                <big-ui-link
                    [href]="logoutUrl"
                    [linkClass]="'text-white hover:text-white active:text-white focus:text-white flex items-center text-decoration-none font-semibold hover:opacity-90'"
                    (click)="onMenuItemClick()">
                    <big-icon
                        [icon]="iconLogout"
                        [size]="'w-3.5'"
                        class="mr-2 fill-current" />
                    {{ t('signOut') }}
                </big-ui-link>
            </div>

            <div class="overflow-auto">
                <ul class="flex flex-col gap-4 p-10 mb-0">
                    @for (item of menu.items; track $index) {
                        <li>
                            @switch (item.type) {
                                @case (menuItemType.Inbox) {
                                    <big-ui-link
                                        [href]="item.uri"
                                        (click)="onMenuItemClick()"
                                        [linkClass]="'flex justify-between text-decoration-none'">
                                        <span [ngClass]="{ 'font-bold': unreadDocumentsCount() > 0 }">{{ item.title }}</span>
                                        @if (unreadDocumentsCount() > 0) {
                                            <span class="h-6 text-center text-white rounded-xl bg-primary w-9">{{ unreadDocumentsCount() }}</span>
                                        }
                                    </big-ui-link>
                                }
                                @default {
                                    <big-ui-link
                                        [href]="item.uri"
                                        (click)="onMenuItemClick()"
                                        [linkClass]="'block text-decoration-none'">
                                        {{ item.title }}
                                    </big-ui-link>
                                }
                            }
                        </li>
                    }
                </ul>
            </div>
        </div>
        <div class="absolute bottom-0 left-0 right-0 h-6 bg-gradient-to-b from-white/0 to-white dark:from-dark-contrast/0 dark:to-dark-contrast"></div>
    </div>
}
