<header
    class="transition-all duration-300 ease-in bg-default md:translate-y-0"
    [class.-translate-y-10]="centeredTitle() && !showCenteredTitle()"
    [class.translate-y-0]="centeredTitle() && showCenteredTitle()"
    [class.md:shadow-none]="scrollPositionY() === 0"
    [class.shadow-dropdown]="scrollPositionY() > 0">
    <div class="hidden bg-alt md:block">
        <div class="container">
            <ng-content select="[secondaryHeaderContent]"></ng-content>
        </div>
    </div>
    <ng-content select="[centeredTitle]"></ng-content>
    <div class="container py-2">
        <ng-content select="[primaryHeaderContent]"></ng-content>
    </div>
    <ng-content></ng-content>
</header>
