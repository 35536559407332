import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ServiceToolBaseComponent, ServiceToolFieldTrackingDirective } from '@big-direkt/service-tools/base';
import { UiAccordionItemComponent } from '@big-direkt/ui/accordion';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { ButtonGroupDataModel, UiButtonGroupComponent } from '@big-direkt/ui/button-group';
import { UiFormRowComponent } from '@big-direkt/ui/form-row';
import { UiNumberInputComponent } from '@big-direkt/ui/input';
import { ScrollService, WindowService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigMediumDokument, IconBigMediumPfeilLinks, IconComponent } from '@big-direkt/utils/icons';
import { TranslocoDirective } from '@jsverse/transloco';
import { DiabetesRiskDiagnosisResultsBlockItem } from '../models/diabetes-risk-diagnosis-results.model';
import { PrediabetesCheckupForm } from '../models/prediabetes-checkup-form.model';
import { PrediabetesCheckupService } from '../services/prediabetes-checkup.service';
import { calculateBmi } from '../utils/calculate-bmi';
import BmiTableComponent from './results/bmi-table.component';
import OfferedServicesComponent from './results/offered-services.component';

@Component({
    selector: 'big-service-tools-diabetes-diagnosis-prediabetes-checkup',
    standalone: true,
    templateUrl: './prediabetes-checkup.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideTranslationScope('stDiabetesDiagnosis', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`)),
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        ServiceToolFieldTrackingDirective,
        TranslocoDirective,
        UiAccordionItemComponent,
        UiButtonComponent,
        UiButtonGroupComponent,
        UiFormRowComponent,
        UiNumberInputComponent,
        IconComponent,
        BmiTableComponent,
        OfferedServicesComponent,
    ],
})
export class PrediabetesCheckupComponent extends ServiceToolBaseComponent<PrediabetesCheckupForm> {
    private readonly scrollService = inject(ScrollService);
    private readonly checkupService = inject(PrediabetesCheckupService);
    private readonly windowService = inject(WindowService);

    public readonly documentIcon = IconBigMediumDokument;
    public readonly arrowIcon = IconBigMediumPfeilLinks;

    public result = signal<DiabetesRiskDiagnosisResultsBlockItem | undefined>(undefined);
    public bmi = signal<number | undefined>(undefined);

    public yesNoOptions: ButtonGroupDataModel<boolean>[] = [
        {
            label: 'stDiabetesDiagnosis.label.yes',
            value: true,
        },
        {
            label: 'stDiabetesDiagnosis.label.no',
            value: false,
        },
    ];

    public constructor() {
        super();

        /* eslint-disable @typescript-eslint/unbound-method */
        this.form = this.formBuilder.group({
            age: new FormControl<PrediabetesCheckupForm['age']['value']>(undefined, [Validators.required]),
            height: new FormControl<PrediabetesCheckupForm['height']['value']>(undefined, [
                Validators.required,
                Validators.min(0),
                Validators.max(250), // eslint-disable-line @typescript-eslint/no-magic-numbers
            ]),
            weight: new FormControl<PrediabetesCheckupForm['weight']['value']>(undefined, [
                Validators.required,
                Validators.min(0),
                Validators.max(200), // eslint-disable-line @typescript-eslint/no-magic-numbers
            ]),
            isSmoker: new FormControl<PrediabetesCheckupForm['isSmoker']['value']>(undefined, [Validators.required]),
            highBloodPressure: new FormControl<PrediabetesCheckupForm['highBloodPressure']['value']>(undefined, [Validators.required]),
            highCholosterolLevel: new FormControl<PrediabetesCheckupForm['highCholosterolLevel']['value']>(undefined, [Validators.required]),
        });
        /* eslint-enable @typescript-eslint/unbound-method */
    }

    public submit(event: Event): void {
        event.preventDefault();

        this.hasBeenSubmitted = true;
        this.trackStFormSubmitEvent();

        if (!this.form?.valid) {
            this.scrollService.scroll('big-service-tools-diabetes-diagnosis-prediabetes-checkup .ng-invalid');

            return;
        }

        const bmi = this.calculateBmi();

        if (!bmi) {
            return;
        }

        this.bmi.set(bmi);
        this.result.set(this.checkupService.getDiagnosisBlock(bmi));

        this.scrollService.scroll('big-service-tools-diabetes-diagnosis-prediabetes-checkup');
    }

    public restart(event: Event): void {
        event.preventDefault();

        this.result.set(undefined);
        this.form?.reset();
        this.hasBeenSubmitted = false;
        this.scrollService.scroll('big-service-tools-diabetes-diagnosis-prediabetes-checkup');
    }

    public print(event: Event): void {
        event.preventDefault();

        this.windowService.nativeWindow()?.print();
    }

    private calculateBmi(): number | undefined {
        const formControls = this.form?.controls;

        if (!formControls?.weight.value || !formControls.height.value) {
            return undefined;
        }

        return calculateBmi(formControls.weight.value, formControls.height.value);
    }
}

export default PrediabetesCheckupComponent;
