import { Injectable } from '@angular/core';
import { DiabetesRiskDiagnosisResultsBlock, DiabetesRiskDiagnosisResultsBlockItem } from './../models/diabetes-risk-diagnosis-results.model';

export const PREDIABETES_RESULTS: DiabetesRiskDiagnosisResultsBlock = {
    bmiLt30: {
        healthCheck: ['medical_appointment_service', 'health_check_up_35', 'family_doctor_care', 'hypertension_consequences'],
        treatmentServices: ['bigmedcoach', 'prevention_services_quit_smoking', 'app_quit_smoking'],
        preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
        vaccinations: ['herpes', 'influenza', 'pneumococcal'],
    },
    bmiGt30: {
        healthCheck: ['medical_appointment_service', 'health_check_up_35', 'family_doctor_care', 'hypertension_consequences'],
        treatmentServices: ['bigmedcoach', 'digital_nutrition_therapy', 'digital_health_application', 'prevention_services_quit_smoking', 'app_quit_smoking'],
        preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
        vaccinations: ['herpes', 'influenza', 'pneumococcal'],
    },
    bmiGt40: {
        healthCheck: ['medical_appointment_service', 'health_check_up_35', 'family_doctor_care', 'hypertension_consequences'],
        treatmentServices: ['bigmedcoach', 'prevention_services_quit_smoking', 'app_quit_smoking'],
        preventionServices: ['bigtionaer', 'prevention_courses'],
        vaccinations: ['herpes', 'influenza', 'pneumococcal'],
    },
};

@Injectable({ providedIn: 'any' })
export class PrediabetesCheckupService {
    public getDiagnosisBlock(bmi: number): DiabetesRiskDiagnosisResultsBlockItem | undefined {
        const bmiRange = this.getBmiRange(bmi);

        return PREDIABETES_RESULTS[bmiRange];
    }

    /* eslint-disable @typescript-eslint/no-magic-numbers */
    private getBmiRange(bmi: number): keyof DiabetesRiskDiagnosisResultsBlock {
        if (bmi > 30 && bmi <= 40) {
            return 'bmiGt30';
        } else if (bmi > 40) {
            return 'bmiGt40';
        }

        return 'bmiLt30';
    }
}
