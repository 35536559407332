import { ChangeDetectionStrategy, Component, effect, HostBinding, inject, input, Input, signal } from '@angular/core';
import { BreakpointService } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-ui-header',
    standalone: true,
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    private readonly breakpointService = inject(BreakpointService);

    public readonly scrollPositionY = this.breakpointService.scrollPositionY();
    public readonly showCenteredTitle = signal(true);

    public readonly centeredTitle = input<string | undefined>(undefined);

    @HostBinding('class') @Input() public classList = 'print:hidden';

    public previousScrollPosition = 0;

    public constructor() {
        effect(
            () => {
                const scrollY = this.scrollPositionY();
                this.showCenteredTitle.set(scrollY <= this.previousScrollPosition);
                this.previousScrollPosition = scrollY;
            },
            { allowSignalWrites: true },
        );
    }
}
