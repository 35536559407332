@let riskBlockItem = riskBlock();

@if (form && !riskBlockItem) {
    <ng-container *transloco="let t; prefix: 'stDiabetesDiagnosis'">
        <big-ui-accordion-item
            [index]="0"
            [title]="t('privacy.title')"
            class="mb-6">
            <p>{{ t('privacy.block1') }}</p>
            <p>{{ t('privacy.block2') }}</p>
            <p>{{ t('privacy.block3') }}</p>
            <p>{{ t('privacy.block4') }}</p>
            <p>{{ t('privacy.block5') }}</p>
            <p>{{ t('privacy.block6') }}</p>
            <p>{{ t('privacy.block7') }}</p>
        </big-ui-accordion-item>

        <form
            [formGroup]="form"
            (ngSubmit)="submit($event)">
            <fieldset>
                <legend>
                    <h4>{{ t('title.ageAndGender') }}</h4>
                </legend>
                <big-ui-form-row
                    #genderFormRow
                    [label]="t('label.gender')"
                    [id]="'gender'"
                    [isRequired]="true"
                    [errors]="form.controls.gender.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="genderRadiogroup"
                    [infoTextTemplate]="genderInfo"
                    (infoLayerOpened)="trackStInfoIconEvent(genderFormRow.label!)">
                    <big-ui-button-group
                        class="w-full"
                        [isValid]="form.controls.gender.valid"
                        [isTouched]="form.controls.gender.touched"
                        radiogroupId="genderRadiogroup"
                        [options]="genderOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'gender'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: genderFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.gender.valid,
                        }"
                        formControlName="gender"
                        name="gender" />
                    <ng-template #genderInfo>
                        {{ t('label.genderHelpLayer') }}
                    </ng-template>
                </big-ui-form-row>

                <big-ui-form-row
                    #ageFormRow
                    [id]="'age'"
                    [label]="t('label.age')"
                    [isRequired]="true"
                    [errors]="form.controls.age.errors"
                    [showError]="hasBeenSubmitted">
                    <big-ui-select
                        [id]="'age'"
                        [isValid]="form.controls.age.valid"
                        [options]="ageOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        formControlName="age"
                        name="age"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: ageFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.age.valid,
                        }" />
                </big-ui-form-row>
            </fieldset>

            <fieldset class="mt-10">
                <legend>
                    <h4>{{ t('title.bodyMeasurements') }}</h4>
                    <p>{{ t('title.bodyMeasurementsDescription') }}</p>
                </legend>

                <big-ui-form-row
                    #bodySizeFormRow
                    [label]="t('label.bodySize')"
                    [id]="'bodySize'"
                    [isRequired]="true"
                    [errors]="form.controls.bodySize.errors"
                    [showError]="hasBeenSubmitted">
                    <big-ui-number-input
                        class="w-full"
                        [isValid]="form.controls.bodySize.valid"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'bodySize'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: bodySizeFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.bodySize.valid,
                        }"
                        formControlName="bodySize"
                        name="bodySize" />
                </big-ui-form-row>

                <big-ui-form-row
                    #bodyWeightFormRow
                    [label]="t('label.bodyWeight')"
                    [id]="'bodyWeight'"
                    [isRequired]="true"
                    [errors]="form.controls.bodyWeight.errors"
                    [showError]="hasBeenSubmitted">
                    <big-ui-number-input
                        class="w-full"
                        [isValid]="form.controls.bodyWeight.valid"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'bodyWeight'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: bodyWeightFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.bodyWeight.valid,
                        }"
                        formControlName="bodyWeight"
                        name="bodyWeight" />
                </big-ui-form-row>

                @if (form.controls.gender.value) {
                    <big-ui-form-row
                        #bodyWaistSizeFormRow
                        [label]="t('label.bodyWaistSize')"
                        [id]="'bodyWaistSize'"
                        [isRequired]="true"
                        [errors]="form.controls.bodyWaistSize.errors"
                        [showError]="hasBeenSubmitted"
                        radiogroupId="bodyWaistSizeRadiogroup"
                        [infoTextTemplate]="bodyWaistSizeInfo"
                        (infoLayerOpened)="trackStInfoIconEvent(bodyWaistSizeFormRow.label!)">
                        <big-ui-button-group
                            class="w-full"
                            [isValid]="form.controls.bodyWaistSize.valid"
                            [isTouched]="form.controls.bodyWaistSize.touched"
                            radiogroupId="bodyWaistSizeRadiogroup"
                            [options]="form.controls.gender.value === 'm' ? bodyWaistSizeOptionsMale : bodyWaistSizeOptionsFemale"
                            [showValidation]="hasBeenSubmitted"
                            [isRequired]="true"
                            [id]="'bodyWaistSize'"
                            [bigStFieldTracking]="{
                                metadata,
                                fieldName: bodyWaistSizeFormRow.label!,
                                valid: !hasBeenSubmitted || form.controls.bodyWaistSize.valid,
                            }"
                            formControlName="bodyWaistSize"
                            name="bodyWaistSize" />
                        <ng-template #bodyWaistSizeInfo>
                            <p>{{ t('label.bodyWaistSizeHelpLayerBlock1') }}</p>
                            <p class="mb-0">{{ t('label.bodyWaistSizeHelpLayerBlock2') }}</p>
                        </ng-template>
                    </big-ui-form-row>
                }
            </fieldset>
            <fieldset class="mt-10">
                <legend>
                    <h4>{{ t('title.lifestyle') }}</h4>
                </legend>

                <big-ui-form-row
                    #doesSportsFormRow
                    [label]="t('label.doesSports')"
                    [id]="'doesSports'"
                    [isRequired]="true"
                    [errors]="form.controls.doesSports.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="doesSportsRadiogroup"
                    [infoTextTemplate]="doesSportsInfo"
                    (infoLayerOpened)="trackStInfoIconEvent(doesSportsFormRow.label!)">
                    <big-ui-button-group
                        class="w-full"
                        [isValid]="form.controls.doesSports.valid"
                        [isTouched]="form.controls.doesSports.touched"
                        radiogroupId="doesSportsRadiogroup"
                        [options]="yesNoOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'doesSports'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: doesSportsFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.doesSports.valid,
                        }"
                        formControlName="doesSports"
                        name="doesSports" />
                    <ng-template #doesSportsInfo>
                        <p>{{ t('label.doesSportsHelpLayerBlock1') }}</p>
                        <p class="mb-0">{{ t('label.doesSportsHelpLayerBlock2') }}</p>
                    </ng-template>
                </big-ui-form-row>

                <big-ui-form-row
                    #eatsHighFiberFoodsFormRow
                    [label]="t('label.eatsHighFiberFoods')"
                    [id]="'eatsHighFiberFoods'"
                    [isRequired]="true"
                    [errors]="form.controls.eatsHighFiberFoods.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="eatsHighFiberFoodsRadiogroup"
                    [infoTextTemplate]="eatsHighFiberFoodsInfo"
                    (infoLayerOpened)="trackStInfoIconEvent(eatsHighFiberFoodsFormRow.label!)">
                    <big-ui-button-group
                        class="w-full"
                        [isValid]="form.controls.eatsHighFiberFoods.valid"
                        [isTouched]="form.controls.eatsHighFiberFoods.touched"
                        radiogroupId="eatsHighFiberFoodsRadiogroup"
                        [options]="eatsHighFiberFoodsOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'eatsHighFiberFoods'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: eatsHighFiberFoodsFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.eatsHighFiberFoods.valid,
                        }"
                        formControlName="eatsHighFiberFoods"
                        name="eatsHighFiberFoods" />
                    <ng-template #eatsHighFiberFoodsInfo>
                        {{ t('label.eatsHighFiberFoodsHelpLayerBlock') }}
                    </ng-template>
                </big-ui-form-row>

                <big-ui-form-row
                    #isSmokerFormRow
                    [label]="t('label.isSmoker')"
                    [id]="'isSmoker'"
                    [isRequired]="true"
                    [errors]="form.controls.isSmoker.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="isSmokerRadiogroup"
                    [infoTextTemplate]="isSmokerInfo"
                    (infoLayerOpened)="trackStInfoIconEvent(isSmokerFormRow.label!)">
                    <big-ui-button-group
                        class="w-full"
                        [isValid]="form.controls.isSmoker.valid"
                        [isTouched]="form.controls.isSmoker.touched"
                        radiogroupId="isSmokerRadiogroup"
                        [options]="yesNoOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'isSmoker'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: isSmokerFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.isSmoker.valid,
                        }"
                        formControlName="isSmoker"
                        name="isSmoker" />
                    <ng-template #isSmokerInfo>
                        {{ t('label.isSmokerHelpLayerBlock') }}
                    </ng-template>
                </big-ui-form-row>
            </fieldset>
            <fieldset class="mt-10">
                <legend>
                    <h4>{{ t('title.healthReports') }}</h4>
                </legend>

                <big-ui-form-row
                    #highBloodSugarLevelsFormRow
                    [label]="t('label.highBloodSugarLevels')"
                    [id]="'highBloodSugarLevels'"
                    [isRequired]="true"
                    [errors]="form.controls.highBloodSugarLevels.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="highBloodSugarLevelsRadiogroup">
                    <big-ui-button-group
                        class="w-full"
                        [isValid]="form.controls.highBloodSugarLevels.valid"
                        [isTouched]="form.controls.highBloodSugarLevels.touched"
                        radiogroupId="highBloodSugarLevelsRadiogroup"
                        [options]="yesNoOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'highBloodSugarLevels'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: highBloodSugarLevelsFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.highBloodSugarLevels.valid,
                        }"
                        formControlName="highBloodSugarLevels"
                        name="highBloodSugarLevels" />
                </big-ui-form-row>

                <big-ui-form-row
                    #medicationBloodPressureFormRow
                    [label]="t('label.medicationBloodPressure')"
                    [id]="'medicationBloodPressure'"
                    [isRequired]="true"
                    [errors]="form.controls.medicationBloodPressure.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="medicationBloodPressureRadiogroup">
                    <big-ui-button-group
                        class="w-full"
                        [isValid]="form.controls.medicationBloodPressure.valid"
                        [isTouched]="form.controls.medicationBloodPressure.touched"
                        radiogroupId="medicationBloodPressureRadiogroup"
                        [options]="yesNoOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        [id]="'medicationBloodPressure'"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: medicationBloodPressureFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.medicationBloodPressure.valid,
                        }"
                        formControlName="medicationBloodPressure"
                        name="medicationBloodPressure" />
                </big-ui-form-row>

                <big-ui-form-row
                    #hasRelativesWithDiabetesFormRow
                    [label]="t('label.hasRelativesWithDiabetes')"
                    [id]="'hasRelativesWithDiabetes'"
                    [isRequired]="true"
                    [errors]="form.controls.hasRelativesWithDiabetes.errors"
                    [showError]="hasBeenSubmitted"
                    radiogroupId="hasRelativesWithDiabetesRadiogroup">
                    <big-ui-select
                        [id]="'hasRelativesWithDiabetes'"
                        [isValid]="form.controls.hasRelativesWithDiabetes.valid"
                        [options]="hasRelativesWithDiabetesOptions"
                        [showValidation]="hasBeenSubmitted"
                        [isRequired]="true"
                        formControlName="hasRelativesWithDiabetes"
                        name="hasRelativesWithDiabetes"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: hasRelativesWithDiabetesFormRow.label!,
                            valid: !hasBeenSubmitted || form.controls.hasRelativesWithDiabetes.valid,
                        }" />
                </big-ui-form-row>
            </fieldset>

            <big-ui-button
                [buttonStyle]="'secondary'"
                [id]="'submit'"
                class="block mt-10 text-right"
                (clicked)="submit($event)">
                {{ t('label.submit') }}
            </big-ui-button>
        </form>
    </ng-container>
}

<!-- Result comes here -->
@let bodyMassIndex = bmi();
@if (riskBlockItem && bodyMassIndex) {
    <ng-container *transloco="let t; prefix: 'stDiabetesDiagnosis'">
        <div class="space-y-16">
            <section>
                <big-ui-bar-chart
                    [config]="barChartValues()"
                    class="mb-16" />

                <h3>{{ t('bmiBar.title') }}</h3>

                <big-ui-alert
                    type="info"
                    class="mb-6"
                    [classList]="'flex text-center border-2 rounded-md p-4 flex-column align-items-center dark:bg-dark-background'">
                    <p
                        [innerHTML]="t('bmiBar.explanationIndividualHtml', { risk: riskBar()?.label })"
                        class="mb-0"></p>
                </big-ui-alert>

                @if (riskPointsRange()) {
                    <div
                        class="[&_>_p:last]:mb-0"
                        [innerHTML]="t('bmiBar.explanationDescriptionHtml.' + riskPointsRange())"></div>
                }
            </section>

            <section>
                <big-service-tools-diabetes-diagnosis-offered-services
                    [riskBlockItem]="riskBlockItem"
                    [isOlderThan35]="form?.controls?.age?.value !== 'lt35'"
                    [hasHighBloodPressure]="false"
                    [isSmoker]="!!form?.controls?.isSmoker?.value" />
            </section>

            <section>
                <big-service-tools-diabetes-diagnosis-bmi-table [bodyMassIndex]="bodyMassIndex" />
            </section>

            <section>
                <h3 class="mb-6">{{ t('minifyRisk.title') }}</h3>

                <ul class="space-y-4">
                    <li>
                        <big-ui-accordion-item
                            [index]="1"
                            [title]="t('minifyRisk.item1.title')"
                            [openForPrint]="true">
                            <p [innerHTML]="t('minifyRisk.item1.block1Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item1.block2Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item1.block3Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item1.block4Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item1.block5Html')"></p>
                        </big-ui-accordion-item>
                    </li>
                    <li>
                        <big-ui-accordion-item
                            [index]="2"
                            [title]="t('minifyRisk.item2.title')"
                            [openForPrint]="true">
                            <p [innerHTML]="t('minifyRisk.item2.block1Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item2.block2Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item2.block3Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item2.block4Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item2.block5Html')"></p>
                        </big-ui-accordion-item>
                    </li>
                    <li>
                        <big-ui-accordion-item
                            [index]="3"
                            [title]="t('minifyRisk.item3.title')"
                            [openForPrint]="true">
                            <p [innerHTML]="t('minifyRisk.item3.block1Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item3.block2Html')"></p>
                            <p [innerHTML]="t('minifyRisk.item3.block3Html')"></p>
                        </big-ui-accordion-item>
                    </li>
                </ul>
            </section>

            <section class="print:hidden">
                <h3 class="mb-6">{{ t('print.title') }}</h3>
                <p>{{ t('print.description') }}</p>

                <big-ui-button
                    [buttonStyle]="'secondary'"
                    [classList]="['flex', 'items-center']"
                    [id]="'print'"
                    (clicked)="print($event)"
                    class="block my-6">
                    {{ t('print.button') }}
                    <big-icon
                        [icon]="documentIcon"
                        [size]="'w-5'"
                        class="ml-2 fill-current" />
                </big-ui-button>

                <p><strong [innerHTML]="t('disclaimer.block1Html')"></strong></p>
                <p class="mb-0"><strong [innerHTML]="t('disclaimer.block2Html')"></strong></p>
            </section>

            <button
                (click)="restart($event)"
                type="button"
                class="inline-flex space-x-2 font-bold text-primary hover:text-primary-light print:hidden">
                <big-icon
                    [icon]="arrowIcon"
                    [size]="'w-4'"
                    class="ml-2 translate-y-1 fill-current" />
                <span>{{ t('label.restart') }}</span>
            </button>
        </div>
    </ng-container>
}
