import { LayoutModule } from '@angular/cdk/layout';
import { NgStyle } from '@angular/common';
import { Component, DestroyRef, effect, HostListener, inject, Injector, type OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TabNavComponent, TabNavService, type TabNavLink } from '@big-direkt/a11y';
import { ChatClientModule } from '@big-direkt/chat-client';
import { FormViewModule } from '@big-direkt/form/view';
import { JsonApiClientService, ResourceTypes } from '@big-direkt/json-api-client';
import { NavHeaderComponent, NavSidenavComponent } from '@big-direkt/nav';
import { UiRepository } from '@big-direkt/state/ui';
import { UserModule, UserRepository, UserService } from '@big-direkt/state/user';
import { TrackingModule } from '@big-direkt/tracking';
import { UiLinkModule } from '@big-direkt/ui/link';
import { type NavigationExtrasState } from '@big-direkt/ui/pagination';
import { UiTopBarComponent } from '@big-direkt/ui/top-bar';
import { CoBrowsingService, CookiebotService, ScrollService, WindowService } from '@big-direkt/utils/environment';
import { IconComponent } from '@big-direkt/utils/icons';
import { BreakpointService, PlatformService, ResizedDirective, type ResizedEvent, type TopBarModel } from '@big-direkt/utils/shared';
import { TranslocoPipe } from '@jsverse/transloco';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgOverlayContainerModule } from 'ng-overlay-container';
import { Subject } from 'rxjs';
import { debounceTime, startWith, tap } from 'rxjs/operators';
import { ContentModule } from './content/content.module';
import { FooterComponent } from './footer/footer.component';

@Component({
    selector: 'big-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [
        ChatClientModule,
        ContentModule,
        // TODO: we need to refactor the forms part to work
        // correctly with lazy loading and standalone component
        // atm we need to load the module in the main bundle
        FooterComponent,
        FormViewModule,
        IconComponent,
        LayoutModule,
        LoadingBarRouterModule,
        NavHeaderComponent,
        NavSidenavComponent,
        NgOverlayContainerModule,
        NgStyle,
        ResizedDirective,
        RouterOutlet,
        TabNavComponent,
        TrackingModule,
        // We need to use the pipe instead of the directive here. The directive has issues with hydration/ssr mode
        // and causes the nav-header to render twice in certain conditions.
        // We don't have these issues with the pipe
        TranslocoPipe,
        UiLinkModule,
        UiTopBarComponent,
        UserModule,
    ],
})
export class AppComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly uiRepository = inject(UiRepository);
    private readonly logoutTimeout: number = 3_600_000;
    private readonly userInteraction: Subject<void> = new Subject<void>();
    private readonly breakpointService = inject(BreakpointService);
    private readonly coBrowsingService = inject(CoBrowsingService);
    private readonly cookiebotService = inject(CookiebotService);
    private readonly injector = inject(Injector);
    private readonly platformService = inject(PlatformService);
    private readonly router = inject(Router);
    private readonly userRepository = inject(UserRepository);
    private readonly userService = inject(UserService);
    private readonly a11yNavService = inject(TabNavService);
    private readonly apiClientService = inject(JsonApiClientService);
    private readonly scrollService = inject(ScrollService);
    private readonly windowService = inject(WindowService);

    public readonly footer: TabNavLink = {
        title: 'a11yTabNav.footerLink.title',
        key: 'footer',
        displayValue: 'a11yTabNav.footerLink.displayValue',
        viewPort: 'all',
        order: 50,
    };
    public readonly mainContent: TabNavLink = {
        title: 'a11yTabNav.contentLink.title',
        key: 'content',
        displayValue: 'a11yTabNav.contentLink.displayValue',
        viewPort: 'all',
        order: 30,
    };
    public readonly isFormShown = this.uiRepository.isFormShown;
    public readonly topBars = toSignal<TopBarModel[]>(
        this.apiClientService.find<TopBarModel>(ResourceTypes.NodeBigTopBar).pipe(
            tap(models => {
                this.uiRepository.setIsTopBarShown(models.length > 0);
            }),
        ),
        {
            injector: this.injector,
        },
    );
    public readonly topBarSize = this.breakpointService.topBarSize;
    public readonly headerSize = this.breakpointService.headerSize;

    private navigationExtras: NavigationExtrasState | undefined;

    public ngOnInit(): void {
        this.a11yNavService.registerLinks(this.mainContent, this.footer);

        effect(
            () => {
                if (!this.uiRepository.isCoBrowsingEnabled()) {
                    return;
                }

                this.coBrowsingService.init();
            },
            { injector: this.injector },
        );

        effect(
            () => {
                this.cookiebotService.init();
            },
            { injector: this.injector },
        );

        this.handleTimeout();

        const routerEvents = toSignal(this.router.events, { injector: this.injector });

        effect(
            () => {
                const routerEvent = routerEvents();

                if (routerEvent instanceof NavigationStart) {
                    this.navigationExtras = this.router.getCurrentNavigation()?.extras.state as NavigationExtrasState | undefined;

                    if (this.navigationExtras?.doNotScroll ?? this.platformService.isPlatformServer()) {
                        return;
                    }

                    // Scroll on parameter change (like pagination page increase)
                    if (this.navigationExtras?.paginationChanged) {
                        this.scrollService.scroll(undefined, { focusElement: true });

                        return;
                    }

                    // Scroll on page change
                    this.scrollService.scrollTop('instant');

                    const topNav: HTMLDivElement | null = this.windowService.document().querySelector('#top');

                    if (!topNav) {
                        this.navigationExtras = undefined;

                        return;
                    }

                    topNav.focus();
                    this.navigationExtras = undefined;
                }
            },
            { injector: this.injector },
        );
    }

    public onTopBarResized(event: ResizedEvent): void {
        this.breakpointService.topBarSize.set({
            height: event.newRect.height,
            width: event.newRect.width,
        });
    }

    public onHeaderResized(event: ResizedEvent): void {
        this.breakpointService.headerSize.set({
            height: event.newRect.height,
            width: event.newRect.width,
        });
    }

    @HostListener('window:mousemove')
    @HostListener('window:keyup')
    @HostListener('window:touchstart')
    public onInteraction(): void {
        this.userInteraction.next();
    }

    private handleTimeout(): void {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        effect(
            () => {
                if (!this.userRepository.isLoggedIn()) {
                    return;
                }

                this.userInteraction.pipe(takeUntilDestroyed(this.destroyRef), startWith(undefined), debounceTime(this.logoutTimeout)).subscribe((): void => {
                    this.userService.logout();
                });
            },
            { injector: this.injector },
        );
    }
}
