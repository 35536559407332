<div
    *transloco="let t; prefix: 'stDiabetesDiagnosis'"
    class="relative p-4 pl-10 bg-white rounded-md dark:!bg-dark-contrast">
    <big-icon
        [icon]="iconCheck"
        class="absolute w-4 top-5 left-4 fill-primary dark:fill-dark-primary" />
    <h4>{{ title() }}</h4>
    <ng-content></ng-content>

    @let url = link();
    @if (url?.href) {
        <div class="text-right">
            <a
                [href]="url?.href"
                [target]="url?.target ?? '_blank'"
                class="inline-flex items-center ml-auto space-x-2 font-bold no-underline text-primary dark:text-dark-primary">
                <span>{{ url?.title ?? t('card.more') }}</span>
                <big-icon
                    [icon]="iconArrowRight"
                    [size]="'w-4'"
                    class="block fill-current" />
            </a>
        </div>
    }
</div>
