<label [for]="id">
    <div
        class="flex flex-col items-center gap-2 px-4 py-2 bg-white border-2 rounded-lg sm:flex-row dark:bg-dark-contrast dark:text-white"
        [ngClass]="formControl.value ? 'border-gray-200' : 'border-gray-300'">
        <div class="flex items-center flex-1 mr-auto">
            <div class="relative">
                @if (mode === 'check') {
                    <input
                        [formControl]="formControl"
                        class="flex-shrink-0 block w-6 h-6 border-2 border-gray-300 rounded-sm appearance-none cursor-pointer peer hover:border-gray-500 hover:checked:border-primary checked:border-primary bg-default checked:bg-primary print:checked:bg-white print:block"
                        [id]="id"
                        type="checkbox"
                        [attr.name]="id"
                        (ngModelChange)="onChange(formControl.value)"
                        [value]="formControl.value" />
                    <big-icon
                        [icon]="iconCheck"
                        [size]="'w-4'"
                        class="absolute left-0 hidden ml-1 top-1 peer-checked:block print:hidden print:fill-none fill-white" />
                    @if (formControl.value) {
                        <span class="absolute top-0 hidden text-xl left-1 font-ci print:block">&#x2713;</span>
                    }
                } @else {
                    <big-icon
                        [icon]="iconCheckCircle"
                        [size]="'w-6'"
                        class="block fill-primary print:fill-none" />
                    <span class="absolute top-0 hidden text-xl left-1 font-ci print:block">&#x2713;</span>
                }
            </div>

            <div class="flex flex-1 h-full ml-2 font-semibold dark:bg-dark-contrast">{{ title }}</div>
        </div>
        <div class="flex ml-auto">
            <div
                class="font-semibold aria-disabled:text-gray-200"
                [attr.aria-disabled]="mode === 'check' && !formControl.value">
                {{ valueLabel }}
            </div>
            @if (infoText) {
                <big-ui-info-button
                    [scrollAnchorId]="'help-' + id"
                    class="ml-2 print:hidden"
                    (infoLayerOpened)="onInfoLayerOpened($event)" />
            }
        </div>
    </div>
</label>
@if (isInfoTextOpen() && infoText) {
    <div
        *transloco="let t; prefix: 'uiInfoCheckbox'"
        [id]="'help-' + id"
        class="p-3 mt-2 bg-gray-400 border-2 border-gray-300 rounded-md">
        <div class="mb-2">
            {{ infoText }}
        </div>
        <big-ui-link
            class="relative flex text-primary hover:text-primary-light"
            [href]="href"
            [linkClass]="'ml-auto tracking-wide font-bold no-underline after:absolute after:inset-0 flex items-center'"
            (linkClicked)="onInfoLayerLinkClicked($event, t('readMore'))">
            {{ t('readMore') }}
            <big-icon
                [icon]="iconArrowRight"
                [size]="'w-4'"
                class="mx-2 fill-current shrink-0" />
        </big-ui-link>
    </div>
}
