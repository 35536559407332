<div
    class="py-4 border-b md:py-0 md:border-b-0 border-gray-3"
    [ngClass]="{
        'md:flex md:items-center': orientation === 'horizontal',
    }">
    <div
        class="flex outline-none hover:underline md:hover:no-underline"
        [attr.role]="isMobile() ? 'button' : null"
        [attr.tabindex]="isMobile() ? 0 : -1"
        [attr.aria-expanded]="isActive && isMobile() ? 'true' : null"
        (click)="isActive = !isActive"
        (keydown.enter)="isActive = !isActive">
        @if (menu?.label) {
            <h5
                class="mb-0 grow"
                [class]="headerClass"
                [class.mr-5]="orientation === 'horizontal'">
                {{ menu?.label }}
            </h5>
        }
        <div
            class="flex items-center mb-px ml-auto transition-transform md:hidden"
            [class.rotate-45]="isActive">
            <big-icon
                [icon]="iconPlus"
                [size]="'w-4'"
                class="block outline-none fill-primary" />
            @if (menu?.label) {
                <span class="sr-only">{{ menu?.label }}</span>
            }
        </div>
    </div>
    <div [ngClass]="{ 'hidden md:block': !isActive }">
        <ul
            class="mb-0"
            [class]="listClass"
            [class.flex]="orientation === 'horizontal'">
            @for (item of menu?.items; track $index) {
                <li
                    [ngClass]="{
                        'ml-4 first:ml-0': orientation === 'horizontal',
                        'mt-4': orientation === 'vertical',
                    }">
                    <big-ui-link
                        [href]="item.uri"
                        [linkClass]="'text-decoration-none outline-none block'">
                        <!-- TODO: set svg child selector new, if bootstrap has been removed -->
                        @if (item.icon && iconMap) {
                            <span
                                [ngClass]="{
                                    'hidden md:inline-block lg:hidden': isContactMenu,
                                }">
                                <big-icon
                                    [icon]="iconMap | iconMapToIcon: item.icon"
                                    [size]="item.iconOnly ? 'w-7' : 'w-5'"
                                    [inline]="true"
                                    [class.mr-2]="!item.iconOnly"
                                    class="align-middle fill-primary dark:fill-dark-primary" />
                            </span>
                        }
                        <span [ngClass]="{ 'sr-only': item.iconOnly }">{{ item.title }}</span>
                    </big-ui-link>
                </li>
            }
        </ul>
    </div>
</div>
