<div
    [class.text-center]="textCentered">
    @if (model.heading) {
        <h1
            class="text-break"
            [class.mb-0]="model.publicationDate || model.publicationLocation">
            {{ model.heading }}
        </h1>
    }
    @if (model.publicationDate || model.publicationLocation) {
        <div class="inline-block mb-4">
            {{
                (model.publicationLocation ?? '') +
                    (model.publicationLocation && model.publicationDate ? ', ' : '') +
                    ((model.publicationDate | date: 'dd.LL.yy') ?? '')
            }}
        </div>
    }
    @if (model.introduction) {
        <div
            class="leading-8 text-[1.0625rem] tracking-[.2px] font-semibold text-break"
            [innerHTML]="model.introduction | nl2br | sanitize: securityContext"></div>
    }
</div>
