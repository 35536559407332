import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventBusService, ResultClickType } from '@big-direkt/event-bus';
import { ResourceTypes, RestApiClientService } from '@big-direkt/rest-api-client';
import { ServiceToolMetadata } from '@big-direkt/service-tools/base';
import { UiAlertComponent } from '@big-direkt/ui/alert';
import { UiButtonComponent } from '@big-direkt/ui/button';
import { UiButtonStyleDirective } from '@big-direkt/ui/directives';
import { UiInfoCheckboxComponent } from '@big-direkt/ui/info-checkbox';
import { UiLinkModule } from '@big-direkt/ui/link';
import { UiSpinnerModule } from '@big-direkt/ui/spinner';
import { EnvironmentService, ErrorHandlingService, ScrollService, WindowService } from '@big-direkt/utils/environment';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigLightDrucker, IconBigMediumPfeilLinks, IconComponent } from '@big-direkt/utils/icons';
import { TranslocoDirective } from '@jsverse/transloco';
import { catchError, tap } from 'rxjs';
import { AdvantageCalculatorForm, InsurantGroup } from '../../models/advantage-calculator-form.model';
import { AdvantageCalculatorResultModel } from '../../models/advantage-calculator-result.model';
import { AdvantageCalculatorService } from '../../services/advantage-calculator.service';

@Component({
    selector: 'big-service-tool-advantage-calculator-summary-step',
    standalone: true,
    templateUrl: './summary-step.component.html',
    imports: [
        CurrencyPipe,
        DatePipe,
        FormsModule,
        IconComponent,
        ReactiveFormsModule,
        TranslocoDirective,
        UiAlertComponent,
        UiButtonComponent,
        UiButtonStyleDirective,
        UiInfoCheckboxComponent,
        UiLinkModule,
        UiSpinnerModule,
    ],
    providers: [
        provideTranslationScope('stAdvantageCalculator', /* istanbul ignore next */ async (lang: string, root: string) => import(`../../${root}/${lang}.json`)),
    ],
})
export class AdvantageCalculatorSummaryStepComponent implements OnInit {
    private readonly advantageCalculatorService = inject(AdvantageCalculatorService);
    private readonly environmentService = inject(EnvironmentService);
    private readonly restApiClient: RestApiClientService = inject(RestApiClientService);
    private readonly destroyRef = inject(DestroyRef);
    private readonly errorHandlingService: ErrorHandlingService = inject(ErrorHandlingService);
    private readonly windowService: WindowService = inject(WindowService);
    private readonly scrollService = inject(ScrollService);
    private readonly eventBus = inject(EventBusService);

    public readonly errorPhoneNumber = this.environmentService.errorPhoneNumber;
    public readonly iconArrowLeft = IconBigMediumPfeilLinks;
    public readonly iconPrint = IconBigLightDrucker;
    public readonly servicePhoneNumber = this.environmentService.servicePhoneNumber;

    @Input({ required: true }) public metadata!: ServiceToolMetadata;
    @Output() public readonly recalculateAdvantagesClicked = new EventEmitter<Event>();

    public resultData!: AdvantageCalculatorResultModel;
    public form!: FormGroup<AdvantageCalculatorForm>;
    public isLoading = signal(true);
    public hasDataLoadingError = signal(false);
    public calculationDate = new Date();

    public get totalSum(): number {
        let sum = 0;
        sum = this.form.controls.primaryInsurant.value.sum ?? 0;
        this.form.controls.additionalInsurants.controls.forEach(x => {
            sum += x.value.sum ?? 0;
        });

        return sum;
    }

    public ngOnInit(): void {
        this.restApiClient
            .load<AdvantageCalculatorResultModel>(ResourceTypes.AdvantageCalculatorServiceToolData)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(data => {
                    this.resultData = data;
                    this.advantageCalculatorService.processAdvantages(data.individualAdvantages);
                    this.form = this.advantageCalculatorService.getForm;
                    this.isLoading.set(false);
                    this.scrollService.scroll('big-paragraph-service-tool');
                }),
                catchError((error: Error) => {
                    this.isLoading.set(false);
                    this.hasDataLoadingError.set(true);

                    return this.errorHandlingService.catchError(error);
                }),
            )
            .subscribe();
    }

    public recalculateAdvantages(clickLabel: string): void {
        this.recalculateAdvantagesClicked.emit();
        this.trackResultActionLink(clickLabel, 'back');
    }

    public printAdvantages(clickLabel: string): void {
        this.windowService.nativeWindow()?.print();
        this.trackResultActionLink(clickLabel, 'print');
    }

    public onInfoLayerOpened(fieldName: string, insurantIndex?: number): void {
        this.eventBus.emit({
            key: 'st_result_info_icon_click_event',
            data: {
                id: this.metadata.id,
                name: this.metadata.name,
                state: this.metadata.state,
                insuredPerson: insurantIndex,
                resultNumber: this.calculateInsurantCount(),
                fieldName,
            },
        });
    }

    public onInfoLayerLinkClicked(event: { linkType: 'external_link' | 'internal_link'; linkLabel: string }, fieldName: string, insurantIndex?: number): void {
        this.eventBus.emit({
            key: 'st_result_click_event',
            data: {
                id: this.metadata.id,
                name: this.metadata.name,
                state: this.metadata.state,
                insuredPerson: insurantIndex,
                clickLabel: event.linkLabel,
                resultNumber: this.calculateInsurantCount(),
                fieldName,
                resultClickType: event.linkType,
            },
        });
    }

    public trackResultActionLink(clickLabel: string, resultClickType: ResultClickType): void {
        const checkedBenefits: Record<`checkedBenefitsP${number}`, string | undefined> = {};

        this.calculateBenefitsLabels(checkedBenefits, this.form.controls.primaryInsurant, 1);
        this.form.controls.additionalInsurants.controls.forEach((insurant: FormGroup<InsurantGroup>, index: number) => {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            this.calculateBenefitsLabels(checkedBenefits, insurant, index + 2);
        });

        this.eventBus.emit({
            key: 'st_result_click_event',
            data: {
                id: this.metadata.id,
                name: this.metadata.name,
                state: this.metadata.state,
                clickLabel,
                resultNumber: this.calculateInsurantCount(),
                resultClickType,
                totalBenefits: this.totalSum,
                checkedBenefits,
            },
        });
    }

    private calculateBenefitsLabels(
        checkedBenefits: Record<`checkedBenefitsP${number}`, string | undefined>,
        insurant: FormGroup<InsurantGroup>,
        index: number,
    ): void {
        const checkedBenefitsLabels: string[] = [];
        insurant.controls.advantages.controls
            .filter(x => x.controls.checked.value)
            .forEach(checkedAdvantage => {
                checkedBenefitsLabels.push(checkedAdvantage.controls.advantage.value?.title ?? '');
            });

        checkedBenefits[`checkedBenefitsP${index}`] = checkedBenefitsLabels.join(', ');
    }

    private calculateInsurantCount(): number {
        return this.form.controls.additionalInsurants.length + 1;
    }
}
