<div
    *transloco="let t; prefix: 'appFormHeader'"
    class="fixed top-0 w-full transition-shadow bg-default md:mt-1 md:shadow-none shadow-dropdown z-modal md:static"
    (bigSharedResized)="onHeaderResized($event)">
    <div
        class="container flex items-center justify-between h-14 md:h-11"
        [ngClass]="{ 'md:hidden': isEmbeddedInMobileApp() }">
        @if (!isEmbeddedInMobileApp()) {
            <button
                (click)="closeFormView('start')"
                class="hidden md:block hide-in-mobile-app">
                <img
                    class="w-auto max-w-full h-[36px] lg:h-[39px]"
                    src="assets/logo.svg"
                    [alt]="t('label.companyName')" />
            </button>
        }
        <big-form-mobile-wizard />
        @if (!isEmbeddedInMobileApp() && isFormShown()) {
            <big-ui-button
                [buttonStyle]="'icon'"
                [id]="'close-form'"
                (clicked)="closeFormView('back')"
                class="p-2 leading-4 hide-in-mobile-app"
                [ariaLabel]="t('label.close')"
                [title]="t('label.close')">
                <big-icon
                    [icon]="iconClose"
                    [size]="'w-5 md:w-6'"
                    class="fill-primary" />
            </big-ui-button>
        }
    </div>
</div>

<div class="mt-20 md:mt-0">
    @if (!isEmbeddedInMobileApp()) {
        <big-ui-page-title
            class="md:mb-8 hide-in-mobile-app"
            [model]="pageTitle"
            [headingSize]="'h1'"
            [showArc]="!isMobile()">
            <ng-content></ng-content>
        </big-ui-page-title>
    }
</div>
